import React from 'react';
import { Input } from 'reactstrap';
import { COLORS } from '../../../constants';
import { TAppInputProps } from './types';

const AppInput = ({
    className,
    bkColor = 'gray',
    height = 64,
    textColor = 'dark',
    size = 20,
    ...others
}: TAppInputProps) => {
    return (
        <Input
            style={{
                backgroundColor: COLORS[bkColor],
                color: COLORS[textColor],
                height,
                fontSize: size,
                boxShadow: 'none'
            }}
            className={`rounded-0 px-4 ${className}`}
            {...others}
        />
    );
};

export default AppInput;
