import React from 'react';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import { AboutusScene } from '../scenes/AboutusScene';
import { ContactScene } from '../scenes/ContactScene';
import { HeroScene } from '../scenes/HeroScene';
import { PortfolioScene } from '../scenes/PortfolioScene';
import { TeamScene } from '../scenes/TeamScene';
import { TechStackScene } from '../scenes/TechStackScene';
import { TestimonialsScene } from '../scenes/TestimonialsScene';

const LandingScene = () => {
    return (
        <div className="landing">
            <Header />
            <HeroScene />
            <AboutusScene />
            <TechStackScene />
            <TeamScene />
            <PortfolioScene />
            <TestimonialsScene />
            <ContactScene />
            <Footer />
        </div>
    );
};

export default LandingScene;
