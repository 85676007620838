import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import engage8 from "../../../../../assets/images/engage8.png";
import quartermoon from '../../../../../assets/images/quartermoon.svg';
import reactbolivia from "../../../../../assets/images/reactbolivia.png";
import xtime from "../../../../../assets/images/xtime.png";
import zatar from "../../../../../assets/images/zatar.png";
import { AppSvg, AppText } from '../../../../../components';
import { ROUTES } from '../../../../../constants';

const images = [

  {
    original: zatar,
    thumbnail: zatar,
    thumbnailLabel: "Zatar",
    thumbnailTitle: "Landing Page implementatión For Zatar Platform"

  }, 
    {
        original: xtime,
        description: "Xtime Portal",
        thumbnailLabel: "Xtime First Version",
        height: 23,
        thumbnailTitle: "Landing Page For Xtime.",
        thumbnail: xtime,
    },
  {
    original: reactbolivia,
    thumbnail: reactbolivia,
    thumbnailLabel: "React Bolivia",
    thumbnailTitle: "Landing Page For React Bolivia Community."

  },
  {
    original: engage8,
    thumbnail: engage8,
    thumbnailLabel: "Xtime Engage 8",
    thumbnailTitle: "Xtime Engage 8 Is A Point-Of-Service Solution For Automotive Dealerships."

  },
];

const PortfolioScene = () => {

  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  let isMobile: boolean = (width <= 768);

  return (
    <section id={ROUTES.ourWork.id} className="d-flex ys-portfolio-section flex-column align-content-centor justify-content-center text-center" >
      <img id="quartermoon" className="ys-portfolio-quartermoon" src={quartermoon} alt="quartermoon" />
      <div>
        <div  className="title d-flex flex-row justify-content-center text-center align-items-center pt-5 pb-5">
          <AppText color="white" className={"pr-3"} fontSize={70} text={'Our' } />
          <AppText bold={true} tag="span" fontSize={70}  className="ml-md-4" color="white" text={'Work' }/>
        </div>

        <div className="ys-portfolio-carousel d-flex flex-column flex-md-row justify-content-center text-center align-items-center" >
          <ImageGallery
            items={images}
            thumbnailPosition={isMobile ? 'bottom' : 'right'}
            showNav={false}
            showFullscreenButton={false}
            additionalClass=""
            showPlayButton={false}
            renderThumbInner={(i) => (
                  <div className="thumbnail-item d-flex flex-column flex-sm-row pt-5">
                    {/*<div className="">*/}
                        <AppText tag="p" bold={true} fontSize={20} className="thumbnail-label justify-content-left text-left align-items-left col-7 ml-5" color="white" text={i.thumbnailLabel || ''}/>
                        <AppText fontSize={20} className="thumbnail-label justify-content-left text-left align-items-left ml-5 " color="white" text={i.thumbnailTitle || ''}/>
                    {/*</div>*/}
                </div>
            )}
          />
        </div>
      </div>
      <a
        className="d-flex flex-column align-items-center text-decoration-none pt-5"
        href={`#${ROUTES.testimonials.id}`}
      >
        <AppSvg name="go_down" color="dark" />
        <AppText
          className="mt-2"
          fontSize={12}
          color="gray"
          text={ 'Testimonials' }
        />
      </a>
    </section>
  );
};

export default PortfolioScene;
