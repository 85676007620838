import React, { useState } from 'react';
import {
    AppButton,
    AppSvg,
    AppText,
    SectionTitle
} from '../../../../../components';
import { ROUTES } from '../../../../../constants';

const FRONT_END = 'frontend';
const BACK_END = 'backend';
const DEV_OPS = 'devops';

const GROUPS: { name: string; key: string }[] = [
    {
        name: 'FRONTEND',
        key: FRONT_END
    },
    {
        name: 'BACKEND',
        key: BACK_END
    },
    {
        name: 'DEVOPS',
        key: DEV_OPS
    }
];

const TechStackScene = () => {
    const [activeTab, setActiveTab] = useState(FRONT_END);
    const frontEndStatus = activeTab === FRONT_END ? 'active' : 'inactive';
    const backEndStatus = activeTab === BACK_END ? 'active' : 'inactive';
    const devOpsStatus = activeTab === DEV_OPS ? 'active' : 'inactive';

    return (
        <section
            id={ROUTES.techStack.id}
            className="ys-techstack-section px-4 pb-5 d-flex flex-column align-items-center justify-content-center text-center"
        >
            <SectionTitle>
                <AppText color="gray" text="Our tech" />
                <AppText
                    color="gray"
                    bold
                    className="ml-3"
                    text="stack"
                />
            </SectionTitle>

            <div className="tab-container d-flex">
                {GROUPS.map(({ name, key }) => {
                    const isSelected = activeTab === key;
                    return (
                        <AppButton
                            color={isSelected ? 'gray' : 'transparent'}
                            key={key}
                            omitSize
                            solid
                            textProps={{
                                color: !isSelected ? 'white' : 'bluish',
                                bold: true,
                                text: name
                            }}
                            onClick={() => setActiveTab(key)}
                        />
                    );
                })}
            </div>

            <div className="stack-group">
                {/* ROW 1 */}
                <div className={`stack-item ${frontEndStatus}`}>
                    <AppSvg name="js" />
                    <AppText color="white" fontSize={12} text={'JAVASCRIPT'} />
                </div>
                <div className={`stack-item ${backEndStatus}`}>
                    <AppSvg name="node_js" />
                    <AppText color="white" fontSize={12} text={'NODE JS'} />
                </div>
                <div className={`stack-item ${devOpsStatus}`}>
                    <AppSvg name="docker" />
                    <AppText color="white" fontSize={12} text={'DOCKER'} />
                </div>
                {/* ROW 2 */}
                <div className={`stack-item ${devOpsStatus}`}>
                    <AppSvg name="aws" />
                    <AppText color="white" fontSize={12} text={'AWS'} />
                </div>
                <div className={`stack-item ${frontEndStatus}`}>
                    <AppSvg name="react" />
                    <AppText
                        color="white"
                        fontSize={12}
                        text={'REACT & NATIVE'}
                    />
                </div>
                <div className={`stack-item ${backEndStatus}`}>
                    <AppSvg name="go" />
                    <AppText color="white" fontSize={12} text={'GOLAND'} />
                </div>
                {/* ROW 3 */}
                <div className={`stack-item ${backEndStatus}`}>
                    <AppSvg name="firebase" />
                    <AppText color="white" fontSize={12} text={'FIREBASE'} />
                </div>
                <div className={`stack-item ${devOpsStatus}`}>
                    <AppSvg name="ci_cd" />
                    <AppText color="white" fontSize={12} text={'CI/CD'} />
                </div>
                <div className={`stack-item ${frontEndStatus}`}>
                    <AppSvg name="uxui" />
                    <AppText color="white" fontSize={12} text={'UX/UI'} />
                </div>
            </div>

            <a
                className="d-flex flex-column align-items-center text-decoration-none"
                href={`#${ROUTES.ourTeam.id}`}
            >
                <AppSvg name="go_down" />
                <AppText
                    className="mt-2"
                    fontSize={12}
                    color="gray"
                    text="Our team"
                />
            </a>
        </section>
    );
};

export default TechStackScene;
