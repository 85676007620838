import React from 'react';
import StyledText, { TStyledTextProps } from 'react-styled-text';
import { COLORS } from '../../constants';
import { TColor, TTextTag } from '../../types';
interface IAppTextProps extends TStyledTextProps {
    color?: keyof typeof COLORS;
    highlighColor?: keyof typeof COLORS;
    text: string;
    tag?: TTextTag; // No don't define agains this in this app
}

const AppText = ({ color, highlighColor, text = '', tag = 'span', ...others }: Omit<IAppTextProps, 'children'>) => {
    let defaultColor: TColor = 'dark';

    return (
        <StyledText
            color={COLORS[color || defaultColor]}
            highlighColor={highlighColor && COLORS[highlighColor]}
            // borderColor={}
            // hoverColor={}
            // fontFamily={}
            tag={tag}
            {...others}
        >
            {text}
        </StyledText>
    );
};

export default AppText;
export type TTextProps = Omit<IAppTextProps, 'children'>;
