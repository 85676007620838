import LocalizedStrings from 'localized-strings';
import { languages } from './languages';

const _T = new LocalizedStrings(languages, {
    // pseudo: true, // For testing
    customLanguageInterface: () => {
        return window.navigator.language;
    }
});

export default _T;
