import { TFormErrors, validationFns as vfs } from 'gendecoder-utils';
import React from 'react';
import { useForm } from 'react-100-form';
import { Form, Tooltip } from 'reactstrap';
import { AppButton, AppInput, AppText } from '../../../../../components';
import _T from '../../../../../i18n';

// Needed Form TYPES
type TField = 'name' | 'email' | 'message';
type TFields = { [key in TField]: TField };
type TFieldMap = { [key in TField]: any };
type TErrorMap = { [key in TField]?: keyof TFormErrors };

// Needed FORM DEFINITION
const FIELDS: TFields = { name: 'name', email: 'email', message: 'message' };
const validate = (valueMap: TFieldMap) => {
    const errorMap: TErrorMap = {};
    errorMap.name = vfs.isEmpty(valueMap.name);
    errorMap.email =
        vfs.isEmpty(valueMap.email) || vfs.invalidEmail(valueMap.email);
    errorMap.message =
        vfs.isEmpty(valueMap.message) ||
        vfs.underMinLength(valueMap.message, 10);
    return errorMap;
};
const initialValues: TFieldMap = {
    name: '',
    email: '',
    message: ''
};

const ContactForm = () => {
    const {
        touchedMap,
        errorMap,
        isValid,
        values,
        handleBlur,
        handleChange
    } = useForm<TFieldMap>(initialValues, validate);

    return (
        <>
            <Form className="d-flex flex-column align-items-center">
                <AppInput
                    autoComplete="off"
                    id={FIELDS.name}
                    name={FIELDS.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={_T.contact_form.placeholder.name}
                    value={values[FIELDS.name]}
                />
                <AppInput
                    className="mt-5"
                    id={FIELDS.email}
                    name={FIELDS.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={_T.contact_form.placeholder.email}
                    value={values[FIELDS.email]}
                />
                <AppInput
                    className="mt-5 pt-3"
                    height={112}
                    id={FIELDS.message}
                    name={FIELDS.message}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={_T.contact_form.placeholder.message}
                    type="textarea"
                    value={values[FIELDS.message]}
                />
                <AppButton
                    className="mt-4 px-5"
                    color="primary"
                    disabled={!isValid}
                    rounded
                    size="masive"
                    solid
                    textProps={{
                        color: 'white',
                        bold: true,
                        fontSize: 18,
                        text: _T.button.get_in_touch.toUpperCase()
                    }}
                />
            </Form>
            {(Object.keys(FIELDS) as TField[]).map((key) => (
                <Tooltip
                    isOpen={!!(touchedMap[key] && errorMap[key])}
                    key={key}
                    placement="top-end"
                    target={FIELDS[key]}
                >
                    <AppText
                        color="white"
                        text={_T.error[(errorMap as TErrorMap)[key]!]}
                    />
                </Tooltip>
            ))}
        </>
    );
};

export default ContactForm;
