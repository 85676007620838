import React, { useState } from 'react';
import { Carousel, CarouselItem } from 'reactstrap';
import { AppSvg, AppText, SectionTitle } from '../../../../../components';
import { ROUTES } from '../../../../../constants';

const testimonials: {
    name: string;
    role: string;
    comment: string;
    company: string;
}[] = [
    {
        company: 'Nearsure',
        name: 'Diego Canelas',
        role: 'Manager of Engineering',
        comment:
            "They don't just have the work done. They always have the energy to learn and teach"
    },
    {
        company: 'Xtime',
        name: 'Waldo Mendoza',
        role: 'Team Lead',
        comment:
            "I've seen the process of they making themselves powerful developers and better people"
    },
    {
        company: 'Staywell',
        name: 'Ari Bravasky',
        role: 'Team Lead',
        comment:
            "I've faced complicated death lines working with them and I can say they owned the line"
    }
];

const TestimonialsScene = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === testimonials.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === 0 ? testimonials.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex: any) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    return (
        <section
            className="ys-testimonials-section text-center d-flex flex-column justify-content-center px-3"
            id={ROUTES.testimonials.id}
        >
            <SectionTitle>
                <AppText color="white" text="What others" />
                <AppText
                    bold={true}
                    className="ml-md-4"
                    color="white"
                    text="say"
                />
            </SectionTitle>

            <div className="flex-fill mt-5 d-inline-flex flex-column d-md-block">
                <Carousel
                    activeIndex={activeIndex}
                    next={next}
                    previous={previous}
                >
                    {testimonials.map((item) => {
                        return (
                            <CarouselItem
                                key={item.name}
                                onExiting={() => setAnimating(true)}
                                onExited={() => setAnimating(false)}
                                className="carousel-item"
                            >
                                <h3 className="h1">{item.company}</h3>
                                <div className="h6 font-weight-light mt-2 info">
                                    <p className="comment">{item.name}</p>
                                    <p className="mt-2 comment">
                                        <i>{item.role}</i>
                                    </p>
                                    <p className="mt-2">{item.comment}</p>
                                </div>
                            </CarouselItem>
                        );
                    })}
                    <div className="mt-5">
                        {testimonials.map((item, index) => (
                            <AppSvg
                                key={item.name}
                                role="button"
                                onClick={() => goToIndex(index)}
                                name={
                                    index === activeIndex
                                        ? 'ellipsis'
                                        : 'ellipsisEmpty'
                                }
                                className={index ? 'ml-4' : ''}
                            />
                        ))}
                    </div>
                </Carousel>
            </div>

            <a
                className="d-flex flex-column align-items-center text-decoration-none mt-5"
                href={`#${ROUTES.contactUs.id}`}
            >
                <AppSvg name="go_down" />
                <AppText
                    className="mt-2"
                    fontSize={12}
                    color="gray"
                    text="Contact us"
                />
            </a>
        </section>
    );
};

export default TestimonialsScene;
