import React from 'react';
import LogoSvg from '../assets/images/logo.svg';

type TProps = {
    width?: number;
    height?: number;
} & React.HtmlHTMLAttributes<HTMLImageElement>;

const Logo = ({ width = 180, height = 30, ...others }: TProps) => {
    return (
        <img
            src={LogoSvg}
            alt="Yasoft"
            width={width}
            height={height}
            {...others}
        />
    );
};

export default Logo;
