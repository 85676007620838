import React from 'react';
import {
    AppButton,
    AppSvg,
    AppText,
    SectionTitle
} from '../../../../../components';
import { ROUTES } from '../../../../../constants';
import _T from '../../../../../i18n';

const HeroScene = () => {
    return (
        <section className="ys-hero-section text-center text-md-left d-flex flex-column justify-content-center">
            <SectionTitle first>
                <AppText bold color="gray" text={_T.hero_scene.great} />
                <AppText
                    bold
                    className="ml-md-4"
                    color="yellow"
                    text={_T.hero_scene.ideas}
                />
                <br className="d-none d-md-block" />
                <AppText bold color="primary" text={_T.hero_scene.fastly} />
                <AppText
                    className="ml-md-4"
                    color="gray"
                    text={_T.hero_scene.done}
                />
            </SectionTitle>

            <div className="mt-4 ys-lead-text">
                <AppText color="gray" tag="p" text={_T.hero_scene.description_1} />
                <AppText color="gray" tag="p" text={_T.hero_scene.description_2} />
            </div>

            <div className="flex-fill mt-5 d-inline-flex flex-column d-md-block">
                <a href={`#${ROUTES.contactUs.id}`}>
                    <AppButton
                        omitSize
                        solid
                        color="primary"
                        className="ya-action-btn px-5 align-self-center align-self-md-start"
                        textProps={{
                            color: 'white',
                            bold: true,
                            text: _T.button.get_in_touch.toUpperCase()
                        }}
                    />
                </a>
                <AppButton
                    omitSize
                    className="ya-action-btn mt-4 mt-md-0 ml-md-4 px-5 align-self-center align-self-md-start"
                    solid
                    color="yellow"
                    textProps={{
                        bold: true,
                        color: 'dark',
                        text: _T.button.free_15min_consult.toUpperCase()
                    }}
                />
            </div>
            <a
                className="d-flex flex-column align-items-center text-decoration-none mt-5"
                href={`#${ROUTES.whoWeAre.id}`}
            >
                <AppSvg name="go_down" />
                <AppText
                    className="mt-2"
                    fontSize={12}
                    color="gray"
                    text={_T.hero_scene.about_us}
                />
            </a>
        </section>
    );
};

export default HeroScene;
