import React from 'react';

export const SectionTitle = ({
    first = false,
    className,
    children,
    ...others
}: {
    first?: boolean;
} & React.HtmlHTMLAttributes<any>) => {
    const Tag = first ? 'h1' : 'h2';
    return (
        <Tag
            className={`ys-section-title d-flex flex-column d-md-block ${className}`}
            {...others}
        >
            {children}
        </Tag>
    );
};
