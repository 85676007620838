import React from 'react';
import { APPNAME, FOOTER_NAV } from '../constants';
import Logo from './Logo';
import AppText from './typography/AppText';

const Footer = () => {
    const YEAR = new Date().getFullYear();

    return (
        <footer className="pt-4 pb-3 d-flex flex-column flex-md-row align-items-center justify-content-around">
            <a href="/#">
                <Logo width={89} height={24} />
            </a>

            <ul className="nav">
                {FOOTER_NAV.map(({ value, label }, index) => {
                    return (
                        <li className="nav-item" key={index}>
                            <a className="nav-link" rel="noopener noreferrer" target="_blank" href={value}>
                                <AppText
                                    fontSize={12}
                                    color="gray"
                                    text={label}
                                />
                            </a>
                        </li>
                    );
                })}
                <li className="nav-item">
                    <a className="nav-link disabled" href="/">
                        <AppText
                            fontSize={12}
                            color="gray"
                            text={`${YEAR} @${APPNAME}`}
                        />
                    </a>
                </li>
            </ul>
        </footer>
    );
};

export default Footer;
