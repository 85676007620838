import React, { CSSProperties } from 'react';
import { COLORS, LENGTH } from '../../constants';
import { TColor, TSize } from '../../types';
import AppText, { TTextProps } from '../typography/AppText';
import './button.css';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    omitSize?: boolean; // Set as true when responsiveness is key and size is managed with CSS
    color?: TColor;
    rounded?: boolean;
    size?: TSize;
    solid?: boolean;
    textProps: TTextProps;
}

const AppButton = ({
    className = '',
    color = 'primary',
    omitSize = false,
    rounded,
    size = 'medium',
    solid,
    style,
    textProps,
    ...others
}: IProps) => {
    const height = LENGTH[size];
    const customStyles: CSSProperties = {};

    if (!omitSize) {
        customStyles.height = height;
        customStyles.paddingLeft = height * 0.4; // 40 percent of height
        customStyles.paddingRight = height * 0.4; // 40 percent of height
        rounded && (customStyles.borderRadius = height * 0.5); // 50 percent of height
    }

    if (solid) {
        customStyles.border = 'none';
        customStyles.background = COLORS[color];
        textProps.color = textProps.color || 'white';
    } else {
        customStyles.borderColor = COLORS[color];
        customStyles.borderWidth = height * 0.04; // 5 percent of height
        textProps.color = textProps.color || color;
    }

    return (
        <button
            className={`app-button ${className}`}
            style={{ ...customStyles, ...style }}
            {...others}
        >
            <AppText color={color} {...textProps} />
        </button>
    );
};

export default AppButton;
