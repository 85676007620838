import React, { RefObject, useEffect, useRef, useState } from 'react';
import SmoothScroll from 'smooth-scroll';
import { AppText } from '.';
import { COLORS, navBarItems } from '../constants';
import { AppSvg } from './Icon/AppSvg';
import Logo from './Logo';

// const languages = ['English', 'Spanish', 'German'];

const useNavScroll = (navRef: RefObject<HTMLElement>) => {
    let scrollPositionRef = useRef<number>(0);

    useEffect(() => {
        new SmoothScroll('a[href*="#"]', {
            speed: 300
        });
    }, []);

    useEffect(() => {
        function handleScroll() {
            const currentScrollPos = window.pageYOffset;
            const navHeight = navRef.current!.offsetHeight;

            // For show/hide menu when scrolling
            if (scrollPositionRef.current > currentScrollPos) {
                navRef!.current!.style.top = '0';
            } else if (currentScrollPos > navHeight / 2) {
                navRef!.current!.style.top = `-${navHeight}px`;
            }

            scrollPositionRef.current = currentScrollPos;
        }

        document.addEventListener('scroll', handleScroll);
        return () => document.removeEventListener('scroll', handleScroll);
    }, [navRef]);
};

const MobileNav = ({ close }: { close: () => void }) => {
    useEffect(() => {
        const body: HTMLBodyElement = document.querySelector('body')!;
        body.classList.add('ys-modal-open');
        return () => body.classList.remove('ys-modal-open');
    }, []);

    return (
        <nav
            className="text-center d-flex flex-column d-md-none w-100 ys-mobile-nav"
            style={{ backgroundColor: COLORS.third }}
        >
            <div className="text-right pt-4 pr-4">
                <AppSvg name="close" onClick={close} role="button" />
            </div>

            <a href="/#" onClick={close} className="mt-4">
                <Logo width={190} height={50} />
            </a>

            <ul className="list-unstyled mb-0 flex-fill">
                {navBarItems.map(({ id, text }, index) => (
                    <li className={index ? 'mt-4' : 'mt-0'} key={id}>
                        <a href={`#${id}`} onClick={close}>
                            <AppText color="link" text={text} />
                        </a>
                    </li>
                ))}
            </ul>

            {/* TODO: Implement language combo once we support localization */}

            {/* <div className="d-flex mt-5 px-3">
                {languages.map((item) => (
                    <AppButton
                        className="border-0"
                        color="white"
                        textProps={{ message: { text: item } }}
                    />
                ))}
            </div> */}

            <div className="d-flex align-items-center w-100 justify-content-around px-5 py-4">
                <AppSvg name="facebook" />
                <AppSvg name="linkedIn" />
                <AppSvg name="email" />
            </div>
        </nav>
    );
};

const Header = () => {
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
    const toggleNavBar = () => setIsMobileNavOpen(!isMobileNavOpen);
    const navRef = useRef<HTMLElement>(null);
    useNavScroll(navRef);

    return isMobileNavOpen ? (
        <MobileNav close={toggleNavBar} />
    ) : (
        <nav
            className="d-flex align-items-center position-fixed w-100 px-4 ys-nav"
            ref={navRef}
        >
            <AppSvg
                className="d-md-none"
                name="bars"
                role="button"
                onClick={toggleNavBar}
            />

            <a href="/#">
                <Logo width={120} height={32} className="d-none d-md-block" />
            </a>

            <ul className="list-unstyled d-none d-md-flex m-0 ml-4">
                {navBarItems.map(({ id, text }) => (
                    <li className="ml-4" key={id}>
                        <a href={`#${id}`}>
                            <AppText color="link" text={text} />
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Header;
