import React from 'react';
import { TColor } from '../../types';
import SVGs from './svgs';

type TProps = {
    name: keyof typeof SVGs;
    color?: TColor;
} & React.SVGProps<SVGAElement>;

export const AppSvg = ({ name, ...others }: TProps) => {
    const Svg = SVGs[name];
    return <Svg {...others} />;
};
