import { TStrings } from './types';

export const en: TStrings = {
    nav_item: {
        who_we_are: 'Who we are',
        tech_stack: 'Tech Stack',
        our_team: 'Our team',
        our_work: 'Our work',
        testimonials: 'Testimonials',
        contact_us: 'Contact Us'
    },
    hero_scene: {
        great: 'Great',
        ideas: 'Ideas',
        fastly: 'Fastly',
        done: 'done!',
        description_1: 'We build highly polished, cross-platform user',
        description_2: 'experiences with React and React Native',
        about_us: 'About us'
    },
    contact_scene: {
        become_a: 'Become a',
        client: 'Client',
        subtitle: "Let's build something great together!",
        info: '(You will hear back from us within 24 hours)',
        question: 'Do you want to have a quick call?',
        address_title: 'Where can you find us',
        city_country_1: 'Stuttgart, Deutschland',
        address_1: 'Vischer Str 2',
        phone_1: '+49 16098484',
        city_country_2: 'La paz, Bolivia',
        address_2: 'Irpavi, Olmos 23 St.',
        phone_2: '+591 77428660'
    },
    language: {
        english: 'English',
        germany: 'Germany',
        spanish: 'Spanish'
    },
    whoweare: {
        subtitle1: 'Growing Together',
        subtitle2: 'Valuable Solutions',
        subtitle3: 'Efficiency',
        paragraph1:
            'Sharing knowledge is the perfect way of giving something without loosing something',
        paragraph2:
            'Great results come from great details, great details come from a great team and you are part of the team!',
        paragraph3:
            'Your idea is the base, our quick execution is the path and the result is your satisfaction'
    },
    footer: {
        privacy: 'Privacy Policy',
        terms: 'Terms of use'
    },
    error: {
        invalid_email: 'Invalid Email',
        invalid_password: '',
        not_equal_to: '',
        over_max_length: '',
        over_max_value: '',
        is_empty: 'Required',
        under_min_length: 'Too short',
        under_min_value: '',
        without_lower_case: '',
        without_number: '',
        without_special_character: '',
        without_upper_case: ''
    },
    contact_form: {
        placeholder: {
            name: 'Your name',
            message: 'What is your message?',
            email: 'Your email'
        }
    },
    button: {
        get_in_touch: 'Get in touch',
        free_15min_consult: 'Free - 15min consult'
    }
};
