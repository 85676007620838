import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import halfcomet from '../../../../../assets/images/halfcomet.svg';
import { AppSvg, AppText, SectionTitle } from '../../../../../components';
import { ROUTES } from '../../../../../constants';
import guille from '../../../../../assets/images/guille.png'
import mario from '../../../../../assets/images/mario.png'
import mauri from '../../../../../assets/images/mau.png'

const images = [
    {
        original: mauri,
        thumbnail: mauri,
        thumbnailTitle: 'Mauricio De La Quintana',
        originalTitle: 'Fullstack Engineer',
        description:
            'Software Engineer with 5+ years of experience in software development highly Interested in smart abstractions and best practices. Specialised in frontend development using Angular, React and Javascript. Currently passionated about highly distributed backend solutions using Golang and API development and API Management solutions.'
    },
    {
        original: mario,
        thumbnail: mario,
        thumbnailTitle: 'Mario Medrano',
        originalTitle: 'Fullstack Engineer',
        description:
            'Over 6 years of experience working remotely in the software industry. A guy with highly analytical prowess and  great skill for decision taking'
    },
    {
        original: guille,
        thumbnail: guille,
        thumbnailTitle: 'Guillermo Paredes',
        originalTitle: 'Front End Specialist',
        description:
            'I\'m a software development engineer with +5 years of experience. I develop web applications, mobile applications that help small and big companies to grow their business. I enjoy sharing knowledge in technology organizing events and meetups. My favorite topics are JavaScript, React, and Angular.'
    }
];

const TeamScene = () => {
    const [width, setWidth] = useState<number>(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);
    let isMobile: boolean = width <= 768;

    return (
        <section id={ROUTES.ourTeam.id} className="ys-team-section d-flex flex-column align-content-stretch justify-content-center text-center">
            <div className="d-flex flex-column flex-md-row justify-content-center align-items-center mt-5">
                <div
                    className="ys-team-svg"
                    style={{
                        position: 'absolute',
                        left: '-150px',
                        top: '180vh'
                    }}
                >
                    <img alt="halfmoon" src={halfcomet} />
                </div>

                <SectionTitle className=" d-flex  flex-row mt-5 flex-md-row justify-content-center text-md-right text-center align-items-center mt-5 ">
                    <AppText text="The amazing "></AppText>
                    <AppText bold text="crew behind "></AppText>
                    <AppText text="Yasoft "></AppText>
                    <br className="d-none d-md-block" />
                    <AppText
                        tag="span"
                        fontSize={32}
                        text="Meet The Team"
                    ></AppText>
                </SectionTitle>

                <div className="ys-team-title col-6  p-5  d-flex flex-column flex-md-row justify-content-center text-center align-items-center">
                    <ImageGallery
                        items={images}
                        showPlayButton={false}
                        thumbnailPosition={isMobile ? 'top' : 'right'}
                        showNav={false}
                        showFullscreenButton={false}
                        renderItem={(i) => (
                            <div>
                                <img
                                    alt=""
                                    className="image-gallery-image"
                                    src={i.original}
                                    title="thumnbnail"
                                />
                                <SectionTitle className="white-space-pre-wrap d-flex mt-5 mb-5 flex-row flex-md-row white-space-normal ">
                                    <AppText
                                        fontSize={32}
                                        text={i.thumbnailTitle || ''}
                                    ></AppText>
                                    <br className="d-none d-md-block" />
                                    <AppText
                                        className="mt-3"
                                        fontSize={20}
                                        bold
                                        text={i.originalTitle || ''}
                                    ></AppText>
                                    <AppText
                                        className="mt-3"
                                        fontSize={15}
                                        tag="p"
                                        text={i.description || ''}
                                    ></AppText>
                                </SectionTitle>
                            </div>
                        )}
                    />
                </div>
            </div>
            <a
                className="d-flex flex-column align-items-center text-decoration-none"
                href={`#${ROUTES.ourWork.id}`}
            >
                <AppSvg name="go_down" color="dark" />
                <AppText
                    className="mt-2"
                    fontSize={12}
                    color="dark"
                    text="Our Work"
                />
            </a>
        </section>
    );
};

export default TeamScene;
