import _T from './i18n';
import { TLanguage } from './i18n/types';
import { TOption, TRoute, TSize } from './types';

export const APPNAME = 'YaSoft';
export const CALENDLY_URL: string = 'https://calendly.com/yasoft';

export const COLORS = {
    primary: '#FF5C00',
    secondary: '#0897F2',
    third: '#000426',
    link: '#9e9bbd',
    dark: '#000000',
    white: '#F9F9FF',
    yellow: '#FFD000',
    gray: '#E4E3EE',
    bluish: '#070140',
    transparent: 'transparent'
};
export const LENGTH: { [key in TSize]: number } = {
    tiny: 20,
    small: 30,
    medium: 40,
    large: 50,
    masive: 60
};

export const HEADER_LANGUAGES: TOption<TLanguage>[] = [
    {
        value: 'en',
        label: _T.language.english
    },
    {
        value: 'de',
        label: _T.language.germany
    },
    {
        value: 'es',
        label: _T.language.spanish
    }
];

export const FOOTER_NAV: TOption[] = [
    {
        value: 'https://www.privacypolicygenerator.info/live.php?token=sODEz8YylNU0bgfbLVLogdptZykswhTr',
        label: _T.footer.privacy
    },
    {
        value: '/#2',
        label: _T.footer.terms
    }
];

export const ROUTES = {
    whoWeAre: {
        text: _T.nav_item.who_we_are,
        id: 'whoWeAre'
    },
    techStack: {
        text: _T.nav_item.tech_stack,
        id: 'techStack'
    },
    ourTeam: {
        text: _T.nav_item.our_team,
        id: 'ourTeam'
    },
    ourWork: {
        text: _T.nav_item.our_work,
        id: 'ourWork'
    },
    portafolio: {
        text: 'Portfolio',
        id: 'portfolio'
    },
    testimonials: {
        text: _T.nav_item.testimonials,
        id: 'testimonials'
    },
    contactUs: {
        text: _T.nav_item.contact_us,
        id: 'contactUs'
    }
};

export const navBarItems: TRoute[] = [
    ROUTES.whoWeAre,
    ROUTES.techStack,
    ROUTES.ourTeam,
    ROUTES.ourWork,
    ROUTES.testimonials,
    ROUTES.contactUs
];

export const HEADER_HEIGHT: number = 80;
