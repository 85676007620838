import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import {
    AppButton,
    AppSvg,
    AppText,
    SectionTitle
} from '../../../../../components';
import { ROUTES } from '../../../../../constants';
import _T from '../../../../../i18n';
import { getCalendlyDeepLink } from '../../../../../utils';
import ContactForm from './ContactForm';

const ContactScene = () => {
    const openCalendly = () => window.open(getCalendlyDeepLink());

    return (
        <>
            <section
                id={ROUTES.contactUs.id}
                className="ys-contact-section px-4 px-md-0 text-center"
            >
                <SectionTitle>
                    <AppText color="third" text={_T.contact_scene.become_a} />
                    <AppText
                        bold
                        className="ml-3"
                        color="dark"
                        text={_T.contact_scene.client}
                    />
                </SectionTitle>

                <h3 className="mt-5">
                    <AppText
                        fontSize={20}
                        color="primary"
                        text={_T.contact_scene.subtitle}
                    />
                </h3>

                <p className="mt-2">
                    <AppText
                        fontSize={20}
                        color="third"
                        text={_T.contact_scene.info}
                    />
                </p>

                <Container>
                    <Row className="mt-5">
                        <Col
                            sm="12"
                            md={{ size: 8, offset: 2 }}
                            lg={{ size: 6, offset: 3 }}
                        >
                            <ContactForm />
                        </Col>
                    </Row>
                </Container>

                <p
                    style={{ marginTop: 70 }}
                    className="d-flex flex-column align-items-center"
                >
                    <AppText color="third" text={_T.contact_scene.question} />
                </p>

                <AppButton
                    className="mt-4 px-5"
                    color="yellow"
                    onClick={openCalendly}
                    rounded
                    size="masive"
                    solid
                    textProps={{
                        bold: true,
                        color: 'dark',
                        fontSize: 18,
                        text: _T.button.free_15min_consult.toUpperCase()
                    }}
                />
            </section>

            <div className="ys-directions">
                <h3>
                    <AppText text={_T.contact_scene.address_title} />
                </h3>
                <div className="d-flex flex-column flex-md-row justify-content-md-between mt-4">
                    <div className="flex-fill">
                        <h2>
                            <AppText
                                bold
                                text={_T.contact_scene.city_country_1}
                            />
                        </h2>
                        <p className="mt-2">
                            <AppText text={_T.contact_scene.address_1} />
                        </p>
                        <p className="mt-2">
                            <AppText text={_T.contact_scene.phone_1} />
                        </p>
                    </div>
                    <div className="mt-4 mt-md-0 flex-fill">
                        <h2>
                            <AppText
                                bold
                                text={_T.contact_scene.city_country_2}
                            />
                        </h2>
                        <p className="mt-2">
                            <AppText text={_T.contact_scene.address_2} />
                        </p>
                        <p className="mt-2">
                            <AppText text={_T.contact_scene.phone_2} />
                        </p>
                    </div>

                    <div
                        className="d-flex justify-content-between mt-5 mt-md-0"
                        style={{ width: 130 }}
                    >
                        <AppSvg name="facebook" color="dark" />
                        <AppSvg name="linkedIn" color="dark" />
                        <AppSvg name="email" color="dark" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactScene;
