import React from 'react';
import efficiency from '../../../../../assets/images/efficiency.svg';
import halfmoon from '../../../../../assets/images/halfmoon.svg';
import variableSolutions from '../../../../../assets/images/variableSolutions.svg';
import workshop from '../../../../../assets/images/workshop.svg';
import { AppSvg, AppText, SectionTitle } from '../../../../../components';
import { ROUTES } from '../../../../../constants';

const AboutusScene = () => {

  return (
    <section id={ROUTES.whoWeAre.id} key="1" className="ys-aboutus-section d-flex flex-column align-content-stretch justify-content-center text-center" >
      <img id="halfmoon" className="ys-aboutus-commet" src={halfmoon} alt="halfmoon" />
      <SectionTitle first>
        <AppText color="white" fontSize={70} text='We are a' />
        <AppText color="white" fontSize={70} bold className="ml-3" text={ 'young technology' } />
        <AppText color="white" fontSize={70} className="ml-3" text={'start up' } />
      </SectionTitle>
      <div className="d-flex flex-column flex-md-row justify-content-center text-center align-items-center pt-5 " >
        <div className="mt-5 d-flex flex-column align-items-center text-center">
          <img id="workshop" src={workshop} alt="workshop" />
          <AppText color="white" className="pt-3" fontSize={24} text={'Growing Together' } />
          <AppText color="white" className="ys-paragraph-text pt-2" fontSize={15} text={ 'Sharing knowledge is the perfect way of giving something without loosing something' } />
        </div>
        <div className="mt-5 d-flex offset-xs-0 offset-lg-2 flex-column align-items-center text-center">
          <img id="variableSolutions" src={variableSolutions} alt="variable solutions" />
          <AppText color="white" className="pt-3" fontSize={24} text={ 'Valuable Solutions' } />
          <AppText color="white" className="ys-paragraph-text pt-2" fontSize={15} text={ 'Great results come from great details, great details come from a great team and you are part of the team!' } />
        </div>
        <div className="mt-5 d-flex offset-xs-0 offset-lg-2 flex-column align-items-center text-center">
          <img id="efficiency" src={efficiency} alt="efficiency" />
          <AppText color="white" className="pt-3" fontSize={24} text={'Efficiency'} />
          <AppText color="white" className="ys-paragraph-text pt-2" fontSize={15} text={ 'Great results come from great details, great details come from a great team and you are part of the team!' } />
        </div>
      </div>
      <a
        className="d-flex flex-column align-items-center text-decoration-none mt-5 "
        href={`#${ROUTES.techStack.id}`}
      >
        <AppSvg name="go_down" />
        <AppText
          className="mt-2"
          fontSize={12}
          color="gray"
          // type="body"
          text={'Tech stack' }
        />
      </a>
    </section>

  );
};

export default AboutusScene;
